/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useQuery } from '@tanstack/react-query';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import BundleDetail from './BundleDetail';
import GraphqlService from '../../service/graphqlService';
import { useProject } from '../../config/ProjectContext';
import JsonPopup from '../../components/JsonPopup';

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#F3916A',
    color: theme.palette.primary.contrastText,
  },
  dialogTitleText: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.5rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  detailTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleDivide: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  detailIcon: {
    padding: 0,
  },
  button: {
    color: theme.palette.primary.contrastText,
    // blue
    backgroundColor: '#3F51B5',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
    borderRadius: '5px',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}));

function BundleDetailPopup({ releaseID }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const dialogRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* Button to open the dialog */}
      <IconButton onClick={handleClickOpen} color="primary">
        <VisibilityIcon />
      </IconButton>

      {/* Dialog for showing bundle details */}
      <Dialog
        open={open}
        ref={dialogRef}
        onClose={handleClose}
        aria-labelledby="bundle-detail-dialog-title"
        maxWidth="md"
        fullWidth
      >

        <div className={classes.dialogTitleContainer}>
          <Typography className={classes.dialogTitleText}>
            Bundle Detail
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>

          <BundleDetail releaseID={releaseID} />

        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary" className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

BundleDetailPopup.propTypes = {
  releaseID: PropTypes.string.isRequired,
};

export default BundleDetailPopup;
