/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
  IconButton,
  FormControlLabel,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  TableContainer,
  Table, TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Select,
} from '@material-ui/core';
import { TableHead } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  React, useEffect, useState, useMemo,
} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/Info';
import { Doughnut, Bar } from 'react-chartjs-2';
import moment from 'moment';
import SummaryIcon from '@material-ui/icons/Assessment';
import { useHistory } from 'react-router-dom'; // Assuming you're using react-router
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GraphqlService from '../../service/graphqlService';
import { ReactComponent as DeleteIcon } from '../../assets/ic_bin.svg';
import { ReactComponent as GlobalDeploymentIcon } from '../../assets/ic_global_deployment.svg';
import { ReactComponent as RegexIcon } from '../../assets/ic_regex.svg';
import { ReactComponent as MarketIcon } from '../../assets/ic_market.svg';
import {
  formatToPrintableDate,
} from '../../utils/dateUtils';
import { downloadFile } from '../../utils/exportUtils';
import {
  CSV_FILETYPE, DELETE_DEPLOY_PERMISSION, TOOLTIP_GLOBAL_DEPLOYMENT, TOOLTIP_REGEX_DEPLOYMENT,
  TOOLTIP_MARKET_DEPLOYMENT, TOOLTIP_DEVICE_DEPLOYMENT, TOOLTIP_DEVICE_DEPLOYMENT_CSV,
} from '../../utils/constants';
import Restricted from '../../authorization/Restricted';
import { useSnackbar } from '../../providers/SnackbarContext';
import { useProject } from '../../config/ProjectContext';
import JsonPopup from '../JsonPopup';
import LoadingAnimation from '../LoadingAnimation';
import EditableInput from '../EditableInput';
import isStringifiedObject from '../../utils/isStringifiedObject';

const useStyles = makeStyles((theme) => ({
  rootDetail: {
    flexGrow: 1,
    background: '#E2E2E2',
    height: '100%',
    with: '100%',
  },
  statusBullet: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  detailItem: {
    borderRadius: '8px 0px 0px 8px',
    background: 'white',
  },
  detailInfoItem: {
    padding: 10,
  },
  detailInsight: {
    borderRadius: '0px 8px 8px 0px',
    padding: 8,
  },
  infoSection: {
    textAlign: 'left',
    height: '100%',
    borderRadius: 8,
    padding: 12,
  },
  headerDetailTitle: {
    paddingBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  infoValue: {
    marginBottom: theme.spacing(1),
  },
  headerValue: {
    fontWeight: 'bold',
  },

  details: {
    padding: 0,
  },
  cardTitle: {
    fontSize: 16,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  cardContent: {
    backgroundColor: 'white',
    color: theme.palette.primary.text,
    flexGrow: 1,
  },
  detailsTitle: {
    fontSize: 14,
    paddingRight: '8px',
  },
  accordionContainer: {
    marginLeft: 24,
    marginRight: 24,
    borderRadius: '0px',
  },
  root: {
    padding: 0,
    backgroundColor: 'white',
    marginBottom: 4,
    marginTop: 4,
    borderRadius: '0px',
  },
  summaryRoot: {
    backgroundColor: 'white',
    borderRadius: '0px',
    margin: 0,
  },
  accordionContent: {
    margin: -12,
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  mainDetailTitle: {
    padding: theme.spacing(2),
    fontWeight: 'bold',
  },
  headingTitleElements: {
    fontWeight: theme.typography.h6.fontWeight,
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.text,
  },
  headingDescription: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.secondary.text,
  },
  headingDate: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.text,
  },
  headingDeletedDate: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: 'red',
  },
  detailsText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.secondary.text,
  },
  iconsBox: {
    float: 'center',
    margin: 0,
  },
  icon: {
    color: theme.palette.primary.text,
  },
  block: {
    background: theme.palette.background.typography,

  },
  blockTitle: {
    fontSize: 16,
    fontWeight: 500,

  },
  key: {
    color: theme.palette.primary.subMain,
    whiteSpace: 'nowrap',
  },
  cardHeader: {
    padding: 10,
  },
  tableBodyDetailItem: {
    height: '100%',
    background: 'white',
  },
  tableHeadDetailItem: {
    background: '#F2F2F2',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  bodyValue: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  overallContainer: {
    background: '#E2E2E2',
    padding: 10,
  },
  overallRootContainer: {
    marginBottom: 32,
  },
  infoItems: {
    marginBottom: 6,
  },
  deployHeaderItem: {
    marginBottom: 16,
  },
  tableStatus: {
    margin: 8,
    borderRadius: 8,
  },
  insightContainer: {
    width: '100%',
    height: '100%',
    maxWidth: 350,
    maxHeight: 350,
    alignContent: 'center',
    alignItems: 'center',
  },
  detailSerialFilterStyle: {
    width: '100%',
    minWidth: 250,

  },
  detailStatusFilterStyle: {
    width: '100%',
    minWidth: 190,
    marginTop: 6,
    paddingRight: 12,
  },
  deleteWave: {
    height: '100%',
  },
  developmentTypeIcons: {
    alignSelf: 'flex-start',
  },
  downloadCloud: {
    padding: 0,

  },
  globalDeployIcon: {
    marginTop: 4,
  },
  gridChartProgress: {
    marginTop: 16,
    marginLeft: 16,
  },
  jsonCopyButton: {
    backgroundColor: theme.palette.primary.button,
    color: theme.palette.common.white,
    padding: '8px 16px',
    borderRadius: 12,
    '& :disabled': {
      color: theme.palette.primary.light,
    },
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 42,
  },
  descriptionElements: {
    width: '100%',
    marginTop: -16,
    marginBottom: -12,
  },
}));

const barChartOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
      maxBarThickness: 5,
    },
    y: {
      stacked: true,
    },
  },
};

export default function WaveAccordion({
  wave, id, deleted, deleteWave, expandedProp,
}) {
  const history = useHistory();
  const classes = useStyles();
  const { project } = useProject();
  const openSnackBar = useSnackbar();

  const [overallStatus, setOverallStatus] = useState([]);
  const [expanded, setExpanded] = useState(expandedProp || false);
  const [selectedDeployment, setSelectedDeployment] = useState('');
  const [deploymentDetails, setDeploymentDetails] = useState([]);
  const [barChartData, setBarChartData] = useState(null);
  const [userDescriptionValue, setUserDescriptionValue] = useState('');
  const [waveDescription, setWaveDescription] = useState(wave?.waveDescription || {});

  function printNumberOfTargets(target) {
    try {
      if (target) {
        if (isStringifiedObject(target)) {
          const parsedTarget = JSON.parse(target);
          if (Array.isArray(parsedTarget)) {
            return parsedTarget?.length;
          }
        } else {
          return 1;
        }
      }
      return 1;
    } catch (e) {
      return '*';
    }
  }
  const handleNavigate = (url) => {
    if (selectedDeployment?.deployID && wave?.dateTime) {
      const unixDate = moment(wave.dateTime).unix();
      const newUrl = history.createHref({ pathname: `${url}/${selectedDeployment?.deployID}/${unixDate}` });
      window.open(newUrl, '_blank'); // Opens in a new tab
    }
  };

  function convertToCSV(rows) {
    let header = '';
    // eslint-disable-next-line no-return-assign
    Object.keys(rows[0]).map((pr) => (header += `${pr};`));

    let str = '';
    rows.forEach((row) => {
      str += `${row}\r\n`;
    });
    return `${str}`;
  }

  function exportTargetToCSV() {
    try {
      let target = JSON.parse(wave.target);
      // for some projects the target field that arrives from AssetDeploy API is not an array
      // in these cases we will transform to an array
      if (typeof target === 'string') {
        target = [target];
      }

      if (Array.isArray(target)) {
        // Add header
        target.unshift('serials');

        const csv = convertToCSV(target);

        const exportedFilename = `Wave${selectedDeployment?.deployID}_deploymentSerials`;

        downloadFile(csv, exportedFilename, CSV_FILETYPE);
      } else {
        window.alert('Couldn\'t export serials', 'Could not parse serials into .csv');
      }
    } catch (e) {
      window.alert('Couldn\'t export serials', 'There was an error exporting the serials into .csv');
    }
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackBar('Value copied successfully to the clipboad!', 'success');
    }).catch((err) => {
      openSnackBar('Failed to copy text to clipboard', err);
    });
  };

  const openAsset = (fileId) => {
    const url = `${window.location.origin}/#/assetDetail/${fileId}`;
    window.open(url, '_blank');
  };

  const statusColors = {
    Done: 'green',
    Error: 'red',
    Executing: 'orange',
  };

  async function searchDeploymentDetails({
    startDate, deployId, params,
  }) {
    const response = await GraphqlService.getLifeCycleObject(
      project.code, `machinecount/${project.code}/${deployId}`, JSON.stringify(params), '', startDate, undefined, undefined,
    );

    if (response) {
      const generalData = JSON.parse(response.data);
      return generalData;
    }
    return [];
  }

  const enable = expanded && !!selectedDeployment?.deployID;

  const fetchDeploymentDetails = async ({ deployId, isError, groupBy }) => {
    if (!deployId) return null;

    return searchDeploymentDetails({
      startDate: moment(wave.dateTime),
      deployId,
      params: { isError, groupBy },
    });
  };

  // selectedDeployment when loading first time if waves just contain one deploy is selected by default
  useEffect(() => {
    if (wave.deploys?.length === 1) {
      setSelectedDeployment(wave.deploys[0]);
    } else {
      setSelectedDeployment(null);
    }
  }, [wave.deploys]);

  useEffect(() => {
    if (expanded && wave.deploys?.length > 0) {
      const fetchAllDeploymentDetails = async () => {
        const promises = wave.deploys?.map(async (deploy) => {
          const dataRaw = await fetchDeploymentDetails({ deployId: deploy.deployID, groupBy: 'asset_exit_code' });

          const dataRawSplitted = dataRaw?.validData?.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            const value = item[key];
            if (parseInt(key, 10) >= 0) {
              acc.positives.push(...value);
            } else {
              acc.negatives.push(...value);
            }
            return acc;
          }, { positives: [], negatives: [] });

          return {
            deployID: deploy.deployID,
            file: deploy.file,
            successData: dataRawSplitted.positives,
            errorData: dataRawSplitted.negatives,
          };
        });
        const results = await Promise.all(promises);
        setDeploymentDetails(results);
      };

      fetchAllDeploymentDetails();
    }
  }, [expanded, wave.deploys]);

  useEffect(() => {
    if (deploymentDetails?.length > 0) {
      const labels = [];
      const doneData = [];
      const executingData = [];
      const errorData = [];

      deploymentDetails.forEach((deployment) => {
        const { file, successData, errorData: deploymentErrorData } = deployment;
        const deployLabel = `${file?.type} ${file?.assetDescription?.version}`;
        labels.push(deployLabel);

        // Ensure successData and deploymentErrorData are arrays
        const successDataArray = Array.isArray(successData) ? successData : [];
        const errorDataArray = Array.isArray(deploymentErrorData) ? deploymentErrorData : [];

        // Calculate counts
        const doneCount = successDataArray
          .filter((item) => item.status === 7)
          .reduce((acc, item) => acc + (item?.machineCount || 0), 0);

        const executingCount = successDataArray
          .filter((item) => item.status >= 1 && item.status < 7)
          .reduce((acc, item) => acc + (item?.machineCount || 0), 0);

        const errorCount = errorDataArray
          .reduce((acc, item) => acc + (item?.machineCount || 0), 0);

        // Push counts into data arrays
        doneData.push(doneCount || 0);
        executingData.push(executingCount || 0);
        errorData.push(errorCount || 0);
      });

      // Create bar chart data
      const data = {
        labels,
        datasets: [
          {
            label: 'Done',
            data: doneData,
            backgroundColor: 'green',
            borderWidth: 1,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
          },
          {
            label: 'Executing',
            data: executingData,
            backgroundColor: 'orange',
            borderWidth: 1,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
          },
          {
            label: 'Error',
            data: errorData,
            backgroundColor: 'red',
            borderWidth: 1,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
          },
        ],
      };

      setBarChartData(data);
    }
  }, [deploymentDetails]);

  // this use effect sums the total number of errors, done and executing machines
  useEffect(() => {
    if (selectedDeployment && deploymentDetails?.length > 0) {
      const deploymentData = deploymentDetails.find((item) => item.deployID === selectedDeployment.deployID);
      if (deploymentData) {
        const { successData, errorData: deploymentErrorData } = deploymentData;

        // Calculate statuses for the selected deployment
        const done = successData?.filter((item) => item.status === 7)?.reduce((acc, item) => acc + (item?.machineCount || 0), 0) || 0;
        const executing = successData?.filter((item) => item.status >= 1 && item.status < 7)?.reduce((acc, item) => acc + (item?.machineCount || 0), 0) || 0;
        const error = deploymentErrorData?.reduce((acc, item) => acc + (item?.machineCount || 0), 0) || 0;

        const totalDone = done + error + executing;
        const statusInfo = [
          {
            status: 'Done',
            count: done,
            percentage: done ? Math.round((done / totalDone) * 100) : 0,
          },
          {
            status: 'Error',
            count: error || 0,
            percentage: error ? Math.round((error / totalDone) * 100) : 0,
          },
          {
            status: 'Executing',
            count: executing,
            percentage: executing ? Math.round((executing / totalDone) * 100) : 0,
          },
          {
            status: 'Total',
            count: totalDone,
          },
        ];

        const chartData = {
          datasets: [
            {
              label: 'Deployment Status',
              data: [statusInfo[0].percentage, statusInfo[1].percentage, statusInfo[2].percentage],
              backgroundColor: ['green', 'red', 'orange'],
              hoverOffset: 3,
            },
          ],
        };

        setOverallStatus({
          statusInfo,
          chartData,
        });
      }
    }
  }, [selectedDeployment, deploymentDetails]);

  const doughnutOptions = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    cutout: '70%',
    responsive: true,
  };

  const hasInsight = JSON.stringify(overallStatus?.chartData?.datasets[0]?.data) !== JSON.stringify([0, 0, 0]);

  // Handle change function
  const handleChange = (event) => {
    const deployID = event.target.value;
    if (deployID === '') {
      setSelectedDeployment(null); // Reset to wave details if no deployID selected
    } else {
      setSelectedDeployment(wave.deploys.find((deploy) => deploy.deployID === deployID));
    }
  };

  const isBundle = wave.deploys?.length > 1;

  if (!wave || !statusColors) {
    return null;
  }

  const getTargetValue = (target) => {
    if (isStringifiedObject(target)) {
      return JSON.parse(target);
    }
    return target;
  };

  const getJsonObject = () => {
    if (typeof selectedDeployment === 'object' && selectedDeployment !== null) {
      return {
        waveID: wave.waveID,
        targetMarket: getTargetValue(wave.targetMarket),
        excludeMarket: wave.excludeMarket,
        target: getTargetValue(wave.target),
        targetRegex: wave.targetRegex,
        excludeRegex: wave.excludeRegex,
        ...selectedDeployment,
      };
    }
    if (typeof wave === 'object' && wave !== null) return wave;
    return null;
  };

  async function handleDescriptionChange(description) {
    return GraphqlService.updateWave(project.code, wave.waveID, description);
  }

  const handleInputChange = (description) => {
    handleDescriptionChange(JSON.stringify(description))
      .then((res) => {
        setUserDescriptionValue(description.userDescription);
        // update Version-deployment-accordion-detail
        setWaveDescription(description);

        openSnackBar('Wave description updated successfully!', 'success');
      })
      .catch((err) => {
        openSnackBar(`An error occurred while updating the Wave description. Error: ${err} `, 'error');
      });
  };

  const openDeployment = (deploymentID) => {
    const url = `${window.location.origin}/#/deploymentDetail/${deploymentID}`;
    window.open(url, '_blank');
  };

  return (
    <div className={classes.accordionContainer}>
      <Accordion className={classes.root} id={`wave-accordion-detail-txt${id}`} expanded={expanded} TransitionProps={{ unmountOnExit: true }} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon id={`btnExpand-deployment-accordion-detaill-${id}`} />}
          aria-controls="panel1a-content"
          id={`panel-header-deployment-accordion-detaill-${id}`}
          className={classes.summaryRoot}
          classes={{ content: classes.accordionContent }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            display="flex-start"
          >

            {/* Asset type/Bundle title */}
            <Grid item sm={2}>
              <ListItem>
                <ListItemText
                  id={`list-deployment-accordion-detail-${id}`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Asset/Bundle</Typography>
                  }
                  secondary={(
                    <Typography variant="body2" component="div">
                      {isBundle ? wave.release?.title || '-' : `${selectedDeployment?.file?.type || '-'} ${selectedDeployment?.file?.assetDescription?.version || ''}`}
                    </Typography>
                  )}
                />
              </ListItem>
            </Grid>

            {/* Description */}
            <Grid container sm={2}>
              <ListItem>
                <ListItemText
                  id={`version-deployment-accordion-detail-${id}`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Description</Typography>
                  }
                  secondary={(
                    <Typography component="div" variant="body2">
                      {waveDescription?.userDescription || wave?.waveDescription?.userDescription || wave.deploys[0]?.deployDescription?.userDescription || '-'}
                    </Typography>
                  )}
                />
              </ListItem>
            </Grid>

            {/* dateTime */}
            <Grid container sm={2}>
              <ListItem>
                <ListItemText
                  id={`date-deployment-accordion-detail-${id}`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Date</Typography>
                  }
                  secondary={<Typography variant="body2">{formatToPrintableDate(wave.dateTime)}</Typography>}
                />
              </ListItem>
            </Grid>

            {/* Development Types */}
            <Grid container sm={2} className={classes.developmentTypeIcons}>

              <ListItem>
                <ListItemText
                  id={`Development-type-deployment-accordion-detail-${id}`}
                  primary={
                    <Typography variant="caption" color="textSecondary">Deployment Type</Typography>
                  }
                  secondary={(
                    <Grid container display="flex" direction="row" alignItems="center" spacing={2} justifyContent="center">
                      {!wave?.targetRegex && !wave?.targetMarket && wave?.target !== '*' && (
                        <Grid item>
                          <Typography className={classes.headingTitle} id={`${id}NMachines`}>{printNumberOfTargets(wave?.target)}</Typography>
                        </Grid>
                      )}
                      {!wave?.targetRegex && !wave?.targetMarket && wave?.target !== '*' && (

                        <Grid item>
                          <Tooltip title={`${TOOLTIP_DEVICE_DEPLOYMENT}. ${TOOLTIP_DEVICE_DEPLOYMENT_CSV}`} aria-label="download">
                            <IconButton
                              aria-label="download-button"
                              id={`btnDownloadNMachines-deployment-accordion-detail-${id}`}
                              className={classes.downloadCloud}
                              onClick={(event) => {
                                event.stopPropagation();
                                exportTargetToCSV();
                              }}
                            >
                              <CloudDownloadIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>

                        </Grid>
                      )}
                      {((!wave?.targetRegex) && (wave?.targetMarket === '*' || wave?.target === '*')) && (
                        <Grid item className={classes.globalDeployIcon}>
                          <Tooltip title={TOOLTIP_GLOBAL_DEPLOYMENT} aria-label="deployGlobal">
                            <Typography className={classes.headingTitle}>
                              {/* GlobalDeploymentIcon should be replaced with your actual global deployment icon component */}
                              <GlobalDeploymentIcon className={classes.icon} />
                            </Typography>
                          </Tooltip>

                        </Grid>
                      )}

                      {wave?.targetRegex && (
                        <Tooltip title={TOOLTIP_REGEX_DEPLOYMENT} aria-label="deployRegex" className={classes.globalDeployIcon}>
                          <Typography className={classes.headingTitle}>
                            {/* RegexIcon should be replaced with your actual regex deployment icon component */}
                            <RegexIcon className={classes.icon} />
                          </Typography>
                        </Tooltip>
                      )}

                      {!wave?.target && !wave?.targetRegex && wave?.targetMarket && selectedDeployment?.targetMarket !== '*' && (
                        <>
                          <Typography className={classes.headingTitle}>
                            <p className={classes.headingTitleElements}>{printNumberOfTargets(wave?.targetMarket)}</p>
                          </Typography>
                          <Tooltip title={TOOLTIP_MARKET_DEPLOYMENT} aria-label="deployMarket">
                            <IconButton disabled>
                              {/* MarketIcon should be replaced with your actual market deployment icon component */}
                              <MarketIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Grid>
                  )}
                />
              </ListItem>

            </Grid>

            {/* #Assets */}
            <Grid container sm={2}>
              <ListItem>
                <ListItemText
                  id={`NAssets-deployment-accordion-detail-${id}`}
                  primary={
                    <Typography variant="caption" color="textSecondary">#Assets</Typography>
                  }
                  secondary={<Typography variant="body2">{wave.deploys?.length || 0}</Typography>}
                />
              </ListItem>
            </Grid>

            {/* Delete */}
            <Grid container sm={2} className={classes.deleteWave}>
              <Grid container display="flex" direction="row" justifyContent="center">

                {!deleted && (
                  <Restricted to={[DELETE_DEPLOY_PERMISSION]}>
                    <Grid container alignItems="center">
                      <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        className={classes.iconsBox}
                        control={(
                          <Tooltip title="Delete Wave" arrow>
                            <IconButton
                              size="small"
                              aria-label="delete"
                              id={`deleteIcon-deployment-accordion-detail-${id}`}
                              onClick={(event) => {
                                event.stopPropagation();
                                deleteWave(wave.waveID);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      />
                    </Grid>
                  </Restricted>
                )}

              </Grid>

            </Grid>

          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container className={classes.rootDetail}>
            <Grid container className={classes.overallRootContainer}>

              {/* Left Side Content */}
              <Grid item xs={12} sm={12} lg={8} className={classes.overallContainer}>
                {isBundle && !selectedDeployment && wave.deploys.length > 1 ? (
                  /* If it's a bundle and no deployment is selected, show Bar Chart */
                  <Grid container className={classes.detailItem}>
                    <Grid item xs={12} style={{ padding: 26 }}>

                      {barChartData?.labels && barChartData?.labels?.length > 0 && (
                        <Bar data={barChartData} options={barChartOptions} />
                      )}

                      {!barChartData && (
                        <Grid container alignContent="center" justifyContent="center" className={classes.loadingContainer}>
                          <LoadingAnimation title="Loading deployment status" />
                        </Grid>
                      )}

                    </Grid>
                  </Grid>
                ) : (
                  /* Otherwise, show Doughnut Chart and Deployment Info */
                  <Grid container className={classes.detailItem}>
                    {/* Overall Deployment Status Header */}
                    <Grid item xs={12}>
                      {!isBundle && !overallStatus?.statusInfo?.length ? (
                        <Grid container alignContent="center" justifyContent="center" className={classes.loadingContainer}>
                          <LoadingAnimation title="Loading deployment status" />
                        </Grid>
                      ) : (
                        <Box display="flex" justifyContent="space-between" width="100%">
                          <Typography variant="subtitle1" className={classes.mainDetailTitle}>
                            Overall Deployment Status
                          </Typography>
                          <Box display="flex" alignItems="center">
                            <Tooltip title="View Deployment Details" arrow>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevents accordion from expanding
                                  handleNavigate('/statistics/deploymentDetails');
                                }}
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View Deployment Summary" arrow>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevents accordion from expanding
                                  handleNavigate('/statistics/deploymentStatus');
                                }}
                              >
                                <SummaryIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                    </Grid>

                    {/* Deployment Table */}
                    <Grid item xs={12} lg={8}>
                      {selectedDeployment && overallStatus?.statusInfo?.length && (
                        <TableContainer style={{ width: '100%', overflowX: 'auto', padding: '8px' }}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell align="right">Devices</TableCell>
                                <TableCell align="right">Percentage</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {/* Done */}
                              <TableRow key="done" className={classes.tableRow}>

                                <TableCell component="th" scope="row">
                                  {overallStatus?.statusInfo[0].status}
                                  {' '}
                                  <span
                                    className={classes.statusBullet}
                                    style={{ backgroundColor: statusColors.Done }}
                                  />
                                </TableCell>

                                <TableCell align="right">{overallStatus?.statusInfo[0].count}</TableCell>
                                <TableCell align="right">{overallStatus?.statusInfo[0].percentage !== undefined ? `${overallStatus?.statusInfo[0].percentage}%` : ''}</TableCell>
                              </TableRow>

                              {/* Error */}
                              <TableRow key="error" className={classes.tableRow}>

                                {/* Error */}
                                <TableCell component="th" scope="row">
                                  {overallStatus?.statusInfo[1].status}
                                  {' '}
                                  <span
                                    className={classes.statusBullet}
                                    style={{ backgroundColor: statusColors.Error }}
                                  />
                                </TableCell>

                                <TableCell align="right">{overallStatus?.statusInfo[1].count}</TableCell>
                                <TableCell align="right">{overallStatus?.statusInfo[1].percentage !== undefined ? `${overallStatus?.statusInfo[1].percentage}%` : ''}</TableCell>
                              </TableRow>
                              {/* Executing */}
                              <TableRow key="executing" className={classes.tableRow}>

                                {/* Executing */}
                                <TableCell component="th" scope="row">
                                  In progress
                                  {' '}
                                  <span
                                    className={classes.statusBullet}
                                    style={{ backgroundColor: statusColors.Executing }}
                                  />

                                  <Tooltip title="All the assets that are in phases of request, validation, download, verification, confirmation or execution." aria-label="download">
                                    <InfoIcon className={classes.icon} />
                                  </Tooltip>
                                </TableCell>

                                <TableCell align="right">{overallStatus?.statusInfo[2].count}</TableCell>
                                <TableCell align="right">{overallStatus?.statusInfo[2].percentage !== undefined ? `${overallStatus?.statusInfo[2].percentage}%` : ''}</TableCell>
                              </TableRow>
                              {/* Total */}
                              <TableRow key="total" className={classes.tableRow}>

                                {/* Total */}
                                <TableCell component="th" scope="row">
                                  Total
                                  {' '}
                                  <Tooltip title="Total number of devices that initiated the software update process." aria-label="download">
                                    <InfoIcon className={classes.icon} />
                                  </Tooltip>

                                </TableCell>

                                <TableCell align="right">{overallStatus?.statusInfo[3].count}</TableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Grid>

                    {/* Doughnut Chart */}
                    {hasInsight && overallStatus?.chartData && (
                      <Grid item xs={12} lg={4} className={classes.detailInsight}>
                        <div className={classes.insightContainer}>
                          <Doughnut data={overallStatus?.chartData} options={doughnutOptions} />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>

              {/* Right Side (Info Section) */}
              <Grid item xs={12} sm={12} lg={barChartData?.hasNoValues && isBundle ? 12 : 4} className={classes.detailInfoItem}>
                <Grid className={classes.infoSection}>
                  <Typography variant="subtitle1" className={classes.headerDetailTitle}>
                    Information
                  </Typography>

                  <Grid container>
                    {isBundle && (

                      <FormControl variant="outlined" style={{ width: '100%', marginBottom: 12 }}>
                        <InputLabel>Asset</InputLabel>
                        <Select
                          value={selectedDeployment?.deployID || ''}
                          onChange={handleChange}
                          label="Asset"
                        >
                          <MenuItem value="">
                            <em>Wave details</em>
                          </MenuItem>
                          {wave.deploys.map((deploy) => (
                            <MenuItem key={deploy?.deployID} value={deploy?.deployID}>
                              <Typography variant="body1">{`${deploy.file?.type} ${deploy.file?.assetDescription?.version}`}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    )}
                  </Grid>

                  {/* Wave ID */}
                  <Box display="flex" className={classes.infoItems} alignItems="center">

                    <Box display="flex" flexDirection="column" mb={0}>

                      <Typography variant="body2" className={classes.headerValue} gutterBottom={false}>
                        {selectedDeployment ? 'Deploy ID' : 'Wave ID'}
                      </Typography>

                      <Typography variant="body2" className={classes.bodyValue}>
                        {selectedDeployment?.deployID || wave.waveID}
                      </Typography>

                    </Box>

                    <IconButton onClick={() => copyToClipboard(selectedDeployment?.deployID || wave?.waveID)}>
                      <FileCopyIcon />
                    </IconButton>

                    <IconButton className={classes.detailIcon} onClick={() => openDeployment(selectedDeployment?.deployID)}>
                      <ExitToAppIcon />
                    </IconButton>

                  </Box>

                  {/* Actor */}
                  <Box display="flex" className={classes.infoItems} alignItems="center">

                    <Box display="flex" flexDirection="column" mb={0}>
                      <Typography variant="body2" className={classes.headerValue} gutterBottom={false}>
                        Actor
                      </Typography>
                      <Typography variant="body2" className={classes.bodyValue}>

                        {selectedDeployment ? selectedDeployment.deployDescription?.userName || selectedDeployment.actor : wave.deploys[0].deployDescription?.userName || wave.deploys[0].actor}

                      </Typography>

                    </Box>

                    <IconButton onClick={() => copyToClipboard(wave.deploys[0].deployDescription?.userName || wave.deploys[0].actor)}>
                      <FileCopyIcon />
                    </IconButton>

                  </Box>

                  {/* Wave description */}

                  <div className={classes.infoItems}>
                    <Typography variant="body2" className={classes.headerValue}>
                      Wave Description
                    </Typography>

                    <Box display="flex" flexWrap="wrap" mt={1} mb={1}>
                      {wave?.waveDescription ? Object.entries(wave.waveDescription).map(([key, value]) => (
                        <Box key={key} className={classes.descriptionElements}>
                          <EditableInput
                            label={key}
                            value={value || '-'}
                            onChange={(change) => handleInputChange({ ...wave.waveDescription, [key]: change })}
                            fullWidth
                          />
                        </Box>
                      )) : (
                        <Box key="editableInput" className={classes.descriptionElements}>
                          <EditableInput
                            label="userDescription"
                            value={userDescriptionValue || '-'}
                            onChange={(change) => handleInputChange({ userDescription: change })}
                            fullWidth
                          />
                        </Box>
                      )}
                    </Box>
                  </div>

                  {/* Target */}
                  {wave.targetRegex && (
                    <div className={classes.infoItems}>
                      <Typography variant="body2" className={classes.headerValue}>
                        Target Regex
                      </Typography>
                      <Typography variant="body2" className={classes.infoValue}>
                        {selectedDeployment ? selectedDeployment?.targetRegex : wave.targetRegex}
                      </Typography>
                    </div>
                  )}

                  {/* Target Market */}
                  {wave.targetMarket && (
                    <div className={classes.infoItems}>
                      <Typography variant="body2" className={classes.headerValue}>
                        Target Market
                      </Typography>
                      <Typography variant="body2" className={classes.infoValue}>
                        {selectedDeployment ? selectedDeployment.targetMarket : wave.targetMarket}
                      </Typography>
                    </div>
                  )}

                  {/* Target ExcludeMarket */}
                  {wave.excludeMarket
                    && (
                      <div className={classes.infoItems}>
                        <Typography variant="body2" className={classes.headerValue}>
                          Exclude Market
                        </Typography>
                        <Typography variant="body2" className={classes.infoValue}>
                          {selectedDeployment ? selectedDeployment.excludeMarket : wave.excludeMarket}

                        </Typography>
                      </div>
                    )}

                  {/* Target Exclude */}
                  {wave.exclude && (
                    <div className={classes.infoItems}>
                      <Typography variant="body2" className={classes.headerValue}>
                        Exclude
                      </Typography>
                      <Typography variant="body2" className={classes.infoValue}>
                        {wave.exclude}
                      </Typography>
                    </div>
                  )}

                  {/* Target fileID */}
                  {selectedDeployment?.file?.fileID && (

                    <Box display="flex" className={classes.infoItems} alignItems="center">

                      <Box display="flex" flexDirection="column" mb={0}>
                        <Typography variant="body2" className={classes.headerValue}>
                          Asset
                        </Typography>
                        <Typography variant="body2" className={classes.bodyValue}>
                          Open the associated asset
                        </Typography>

                      </Box>

                      <IconButton aria-label="openAsset" onClick={() => openAsset(selectedDeployment?.file.fileID)}>
                        <OpenInNewIcon />
                      </IconButton>

                    </Box>
                  )}

                  {/* copy all deployment object with json button aligned to right */}
                  <Box display="flex" justifyContent="flex-end">
                    <JsonPopup
                      title="Deployment JSON"
                      jsonObject={getJsonObject()}
                    />
                  </Box>

                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </AccordionDetails>

      </Accordion>
    </div>

  );
}
WaveAccordion.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  wave: PropTypes.any,
  id: PropTypes.string,
  deleted: PropTypes.bool,
  deleteWave: PropTypes.func,
  expandedProp: PropTypes.bool,
};
WaveAccordion.defaultProps = {
  wave: undefined,
  id: undefined,
  deleted: false,
  deleteWave: undefined,
  expandedProp: false,
};
