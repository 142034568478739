/* eslint-disable no-unused-vars */
import {
  Grid,
  makeStyles,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  TableBody,
  Paper,
  TableContainer,
  Table,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  React, useState,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReactJson from 'react-json-view';
import GraphqlService from '../../service/graphqlService';
import EditableInput from '../EditableInput';
import { useProject } from '../../config/ProjectContext';
import { useSnackbar } from '../../providers/SnackbarContext';
import BundleDetailPopup from '../../screens/Bundle/BundleDetailPopup';
import cleanAllNullValues from '../../utils/cleanAllNullValues';
import { StyledTableCell, StyledTableRow } from '../utils/StyledTable';
import {
  NO_INFORMATION_AVAILABLE,
} from '../../utils/constants';

export default function AssetDetail({
  asset: initialAsset,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: 0,
      border: `thin solid ${theme.palette.background.form}`,
      color: theme.palette.primary.text,
      backgroundColor: 'white',
    },
    headingTitle: {
      fontSize: 16,
    },
    headingDate: {
      fontSize: 14,
    },
    details: {
      borderTop: `3px solid ${theme.palette.background.paper}`,
      lineHeight: '90px',
      backgroundColor: alpha(theme.palette.background.paper, 0.1),
    },
    detailsSelected: {
      lineHeight: '9f0px',
      padding: 24,
      backgroundColor: alpha(theme.palette.primary.banner, 0.1),
    },
    detailsTitle: {
      fontSize: 14,
      paddingRight: '8px',
    },
    key: {
      color: theme.palette.primary.subMain,
    },
    cardContent: {
      backgroundColor: 'white',
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      flexGrow: 1,
      borderRadius: 12,
      padding: 12,
    },
    summaryExpanded: {
      backgroundColor: alpha(theme.palette.background.paper, 0.3),
      wordBreak: 'break-word',
    },
    summaryCollapsed: {
      backgroundColor: theme.palette.background.paper,
      wordBreak: 'break-word',
    },
    selectedAsset: {
      backgroundColor: alpha(theme.palette.primary.banner, 0.3),
    },
    jsonCopyButton: {
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
      '& :disabled': {
        color: theme.palette.primary.light,
      },
    },
    reactJsonContainer: {
      width: '100%',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      overflowX: 'auto', // Allow horizontal scrolling if needed
      marginBottom: 18,
    },
    mainContainter: {
      padding: theme.spacing(1),
    },

  }));

  const classes = useStyles();
  const updateAssetMutation = useMutation((variables) => GraphqlService.updateAssetDescription(variables.projectId, variables.fileId, variables.assetDescription));
  const openSnackBar = useSnackbar();
  const { project } = useProject();
  const [asset, setAsset] = useState(initialAsset);

  async function handleDescriptionChange(description) {
    return updateAssetMutation.mutateAsync({
      projectId: project.code,
      fileId: asset.fileID,
      assetDescription: description,
    });
  }

  const handleInputChange = (description) => {
    handleDescriptionChange(JSON.stringify(description))
      .then((res) => {
        setAsset({
          ...asset,
          assetDescription: JSON.parse(res),
        });
        openSnackBar('Asset description updated successfully!', 'success');
      })
      .catch((err) => {
        openSnackBar(`An error occurred while updating the asset description. Error: ${err} `, 'error');
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackBar('Value copied successfully to the clipboard!', 'success');
    }).catch((err) => {
      openSnackBar('Failed to copy text to clipboard', err);
    });
  };

  return (

    <Grid container spacing={1} alignItems="stretch" className={classes.mainContainter}>

      <Grid item xs={12} md={6}>
        <TextField
          label="File ID"
          value={asset.fileID || 'No value for file ID'}
          InputProps={{
            readOnly: true,
            endAdornment: asset.fileID && (
              <InputAdornment position="end">
                <IconButton onClick={() => copyToClipboard(asset.fileID)}>
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>

      {/* Chunks */}
      <Grid item xs={12} md={6}>
        <TextField
          label="Chunks"
          value={Array.isArray(asset.chunks) ? asset.chunks.map((path) => path.split('/').pop()).join(', ') : NO_INFORMATION_AVAILABLE}
          InputProps={{
            readOnly: true,
            endAdornment: Array.isArray(asset.chunks) && (
              <InputAdornment position="end">
                <IconButton onClick={() => copyToClipboard(asset.chunks.map((path) => path.split('/').pop()).join(', '))}>
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          label="Manufacturer Signature"
          value={asset.manufacturerSignature || NO_INFORMATION_AVAILABLE}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
      </Grid>

      {/* Software requirements */}
      <Grid item xs={12} md={4}>
        <TextField
          label="Software Requirements"
          value={asset.softwareRequirements || NO_INFORMATION_AVAILABLE}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
      </Grid>

      {/* Hardware requirements */}
      <Grid item xs={12} md={4}>
        <TextField
          label="Hardware Requirements"
          value={asset.hardwareRequirements || NO_INFORMATION_AVAILABLE}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
      </Grid>

      {/* Type requirements */}
      {asset.typeRequirements && asset.typeRequirements.length > 0 && (

        <Grid item xs={12} md={12} classNane={classes.cardContent}>
          <Typography variant="subtitle1">
            Type requirements for the asset.
          </Typography>
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={12} md={4}>
              <TextField
                label="Type"
                value={asset.typeRequirements[0].type || NO_INFORMATION_AVAILABLE}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Software Requirements"
                value={asset.typeRequirements[0].softwareRequirements || NO_INFORMATION_AVAILABLE}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Hardware Requirements"
                value={asset.typeRequirements[0].hardwareRequirements || NO_INFORMATION_AVAILABLE}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

      )}

      {/* Metadata */}
      {(asset.metaData.major || asset.metaData.minor || asset.metaData.build || asset.metaData.hash) && (
        <Grid item xs={12} className={classes.reactJsonContainer}>
          <Typography variant="subtitle1">
            Metadata for the asset:
          </Typography>
          <ReactJson
            src={cleanAllNullValues(asset?.metaData)}
            theme="rjv-default"
            collapsed
            displayDataTypes={false}
            enableClipboard={false}
            style={{ fontSize: '1rem', lineHeight: '1.5' }}
          />
        </Grid>
      )}

      {asset.assetDescription && (
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1">
            Detailed descriptions for the asset:
          </Typography>
          <Box display="flex" flexWrap="wrap" mt={1} mb={1}>
            {Object.entries(asset.assetDescription).map(([key, value]) => (
              <Box key={key} className={classes.descriptionElements}>
                <EditableInput
                  label={key}
                  value={value || '-'}
                  onChange={(change) => handleInputChange({ ...asset.assetDescription, [key]: change })}
                  fullWidth
                  margin="dense"
                />
              </Box>
            ))}
          </Box>
        </Grid>
      )}

      {asset.releaseIDs && asset.releaseIDs.length > 0 && (

        <Grid item xs={12} className={classes.reactJsonContainer}>
          <Typography variant="subtitle1">
            This Asset is part of the following Bundles:
          </Typography>

          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="selected assets table">

              <TableBody>
                {asset.releaseIDs?.map((releaseID) => (
                  <StyledTableRow key={releaseID}>
                    <StyledTableCell key={`${releaseID}`}>
                      {releaseID}
                    </StyledTableCell>
                    <StyledTableCell id={`${releaseID}-link`}>
                      <BundleDetailPopup releaseID={releaseID} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      )}
    </Grid>

  );
}
AssetDetail.propTypes = {
  asset: PropTypes.instanceOf(Object),
};
AssetDetail.defaultProps = {
  asset: undefined,
};
