/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import {
  Box,
  InputLabel,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Paper,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  React,
} from 'react';
import BundleDetailPopup from '../../../screens/Bundle/BundleDetailPopup';
import { StyledTableCell, StyledTableRow, StyledTableHeaderCell } from '../../utils/StyledTable';

const useStyles = makeStyles((theme) => ({
  labelSummary: {
    color: theme.palette.primary.subMain,
    paddingBottom: '8px',
    paddingTop: '4px',
  },
  summaryContent: {
    minHeight: '116px',
    borderBottom: `1px solid ${theme.palette.primary.subMain}`,
    padding: '16px',
  },
  leftSummary: {
    paddingLeft: '24px',
    flexBasis: '12.666667%',
  },
  rightSummaryTitle: {
    backgroundColor: theme.palette.background.form,
    flexBasis: '7.666667%',
  },
  rightSummary: {
    backgroundColor: theme.palette.background.form,
  },
  firmware: {
    paddingBottom: '4px',
  },
  telemetryTable: {
    marginTop: 4,
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
}));

const openAsset = (fileId) => {
  const url = `${window.location.origin}/#/assetDetail/${fileId}`;
  window.open(url, '_blank');
};

function parseCustomString(str) {
  // Remove the outer braces and split by commas
  const entries = str?.slice(1, -1).split(', ');
  const result = {};

  // Process each entry
  entries.forEach((entry) => {
    const [key, value] = entry.split('=').map((item) => item.trim());

    // Convert value to a number if possible, otherwise treat it as a string
    result[key] = value;
  });

  return result;
}

function getDescription(assetDescription) {
  if (assetDescription?.Version && assetDescription?.Description) {
    return {
      version: assetDescription.Version,
      description: assetDescription.Description,
    };
  }

  if (assetDescription?.version && assetDescription?.description) {
    return assetDescription;
  }

  return parseCustomString(assetDescription);
}

export default function NewDeploymentHeader({
  objectsToDeploy,
}) {
  const classes = useStyles();
  const isBundle = objectsToDeploy?.length > 0 && objectsToDeploy[0].type === 'bundle';
  console.log('objectsToDeploy ==>', objectsToDeploy);
  return (
    <Box>

      <Box m={2}>
        {isBundle && (
          <>
            <InputLabel>
              Bundle to deploy
            </InputLabel>

            <TableContainer component={Paper} className={classes.telemetryTable}>
              <Table>
                <TableBody>
                  {objectsToDeploy && objectsToDeploy.length > 0 && (
                    <StyledTableRow>

                      <>
                        <StyledTableCell>
                          ✅
                          {' '}
                          {`${objectsToDeploy[0].title}`}
                        </StyledTableCell>
                        <StyledTableCell>
                          {`${objectsToDeploy[0].releaseDescription?.description || objectsToDeploy[0].description || ' '}`}
                        </StyledTableCell>

                        <StyledTableCell>
                          <BundleDetailPopup releaseID={objectsToDeploy[0].releaseID} />
                        </StyledTableCell>
                      </>

                    </StyledTableRow>

                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
      <Box m={2}>
        <InputLabel>
          {isBundle ? 'Assets in the bundle' : 'Assets to deploy'}
        </InputLabel>

        <TableContainer component={Paper} className={classes.telemetryTable}>
          <Table>
            <TableBody>
              {objectsToDeploy && objectsToDeploy.length > 0 && objectsToDeploy.map((objectToDeploy) => (
                isBundle && objectToDeploy?.fileIDs?.length > 0 ? (
                  objectToDeploy.fileIDs.map((assetInBundle) => (
                    <StyledTableRow key={assetInBundle}>
                      <StyledTableCell>
                        ✅
                        {' '}
                        {assetInBundle.assetDescription ? getDescription(assetInBundle.assetDescription)?.version : '-'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {objectToDeploy.fileID}
                      </StyledTableCell>
                      <StyledTableCell>
                        {assetInBundle.assetDescription ? getDescription(assetInBundle.assetDescription)?.description : '-'}
                      </StyledTableCell>

                      <StyledTableCell>
                        <IconButton className={classes.detailIcon} onClick={() => openAsset(assetInBundle.fileID)}>
                          <ExitToAppIcon />
                        </IconButton>
                      </StyledTableCell>

                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow key={objectToDeploy.fileID}>
                    <StyledTableCell>
                      ✅
                      {' '}
                      v.
                      {objectToDeploy.assetDescription ? objectToDeploy.assetDescription.Version : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {objectToDeploy.assetDescription?.Description || ' '}
                    </StyledTableCell>

                    <StyledTableCell>
                      <IconButton className={classes.detailIcon} onClick={() => openAsset(objectToDeploy.fileID)}>
                        <ExitToAppIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
NewDeploymentHeader.propTypes = {
  objectsToDeploy: PropTypes.array.isRequired,
};
