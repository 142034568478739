import {
  TableRow,
  TableCell,
  withStyles,
} from '@material-ui/core';

const StyledTableHeaderCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'gray',
    color: theme.palette.common.white,
    width: '9px',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: 'white',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    // selected
    '&$selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))(TableRow);

export { StyledTableCell, StyledTableRow, StyledTableHeaderCell };
