/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  LinearProgress,
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import { useProject } from '../../config/ProjectContext';
import WaveAccordion from '../../components/Wave/WaveAccordion';
import GraphqlService from '../../service/graphqlService';
import YesNoDialog from '../../components/utils/YesNoDialog';
import PaginationComponent from '../../components/Pagination';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { useSnackbar } from '../../providers/SnackbarContext';
import MessageCard from '../../components/utils/MessageCard';

export default function WaveList() {
  const useStyles = makeStyles((theme) => ({
    separate: {
      flexWrap: 'noWrap',
    },
    headerTabPanel: {
      height: 48,
      backgroundColor: theme.palette.background.paper,
    },
    pagination: {
      background: theme.palette.background.paper,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      float: 'right',
      padding: 0,
      width: '100%',
    },
    labelHeaderTabPanel: {
      color: theme.palette.primary.subMain,
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: '16px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center',
      width: '720px',
      height: '48px',
    },
    background: {
      backgroundColor: theme.palette.background.typography,
    },
    button: {
      float: 'right',
      margin: '0 0 16px 24px',
    },
    tableIcons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: 24,
      flexWrap: 'wrap',
    },
    selectPaginationSize: {
      marginRight: '8px',
      borderRadius: 12,
    },
    fileTypeFilterStyle: {
      minWidth: 410,
      marginTop: 6,
    },
    orderDateStyle: {
      minWidth: 130,
      marginTop: 6,
    },
    waveIdFilterStyle: {
      minWidth: 450,
      borderRadius: 12,
      marginRight: 8,
    },
  }));

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { project } = useProject();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertId, setAlertId] = useState('');
  const [alertText, setAlertText] = useState('');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [lastProjectCode, setLastProjectCode] = useState(null);
  const [waveFileTypeFilter, setWaveFileTypeFilter] = useState(null);
  const [lastWaveFileTypeFilter, setLastWaveFileTypeFilter] = useState();
  const [selectedWaveFileTypes, setSelectedWaveFileTypes] = useState([]);
  const [yesNoDialogOpen, setYesNoDialogOpen] = useState(false);
  const [yesNoDialogText, setYesNoDialogText] = useState('');
  const [waveToDelete, setWaveToDelete] = useState(null);
  const [selectedFilterField, setSelectedFilterField] = React.useState('');
  const [filterValue, setfilterValue] = React.useState(null);
  const openSnackBar = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);

  const [releaseIdFilter] = useState(null);
  const [_debouncedReleaseIdFilter, setDebouncedReleaseIdFilter] = useState(releaseIdFilter);

  const [deployIdFilter] = useState(null);
  const [_debouncedDeployIdFilter, setDebouncedDeployIdFilter] = useState(releaseIdFilter);

  const [fileIdFilter] = useState(null);
  const [_debouncedFileIdFilter, setDebouncedFileIdFilter] = useState(fileIdFilter);

  const {
    waveID: waveIDParam,
    waveDescriptionKey: waveDescriptionKeyParam,
    waveDescriptionValue: waveDescriptionValueParam,
  } = useParams();

  useEffect(() => {
    const handler = setTimeout(() => {
      if (releaseIdFilter) setDebouncedReleaseIdFilter(releaseIdFilter);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [releaseIdFilter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (fileIdFilter) setDebouncedFileIdFilter(fileIdFilter);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [fileIdFilter]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (deployIdFilter) setDebouncedDeployIdFilter(deployIdFilter);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [deployIdFilter]);

  const getFileType = waveFileTypeFilter === null ? null : waveFileTypeFilter;

  function openAlert(header, text, id) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
    setAlertId(id);
  }

  const getWave = async () => GraphqlService.getWaveById(project?.code, waveIDParam);

  const {
    data: waveFromParams,
    isLoading: isLoadingWave,
  } = useQuery(['wave', waveIDParam], () => getWave(), {
    enabled: !!waveIDParam && !!project?.code,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });

  const fetchWaves = async () => {
    if (
      project?.code !== lastProjectCode
      || waveFileTypeFilter !== lastWaveFileTypeFilter
    ) {
      setCurrentPage(0);
      setLastProjectCode(project?.code);
      setLastWaveFileTypeFilter(waveFileTypeFilter);
    }
    return GraphqlService.listPaginatedWaves({
      projectId: project?.code,
      page: currentPage,
      pageSize,
      sortDirection,
      fileType: getFileType,
      waveDescriptionKey: waveDescriptionKeyParam,
      waveDescriptionValue: waveDescriptionValueParam,
      fileId: _debouncedFileIdFilter,
      releaseID: _debouncedReleaseIdFilter,
      deployID: _debouncedDeployIdFilter,
    });
  };

  const {
    data: waves,
    error: errorFetchingWaves,
    isLoading: isLoadingWaves,
    refetch,
  } = useQuery(
    [
      'waves',
      project?.code,
      project?.permission,
      currentPage,
      pageSize,
      sortDirection,
      waveFileTypeFilter,
      _debouncedFileIdFilter,
      _debouncedReleaseIdFilter,
      _debouncedDeployIdFilter,
    ],
    () => fetchWaves({
      projectId: project?.code,
      page: currentPage,
      pageSize,
      sortDirection,
      fileType: getFileType,
      _debouncedReleaseIdFilter,
      waveDescriptionKey: waveDescriptionKeyParam,
      waveDescriptionValue: waveDescriptionValueParam,
    }),
    {
      enabled: !!project && !waveIDParam,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      cacheTime: 0,
    },
  );

  const fetchWaveFileTypes = async () => GraphqlService.getDeploymentFileTypes({
    projectId: project?.code,
  });

  const {
    data: waveFileTypes,
    isLoading: isLoadingWaveFileTypes,
  } = useQuery(['waveFileTypes', project?.code, project?.permission, currentPage, pageSize, sortDirection], () => fetchWaveFileTypes({
    projectId: project?.code,
  }), {
    enabled: !!project,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    cacheTime: 0,
  });

  const handleDeploymentFileTypeFilterChange = (event) => {
    const { value } = event.target;
    // Need to handle the selection as an array
    setSelectedWaveFileTypes(value);
    // transform the array in a string with values sepparated by commas
    if (!value) {
      setWaveFileTypeFilter(null);
    } else setWaveFileTypeFilter(value.join(',').toString());
  };

  async function deleteWaveFunc(wave) {
    try {
      setIsDeleting(true);
      await GraphqlService.deleteWave(project?.code, wave.waveID);
      refetch();
      setIsDeleting(false);
      openSnackBar('Wave deleted successfully');
    } catch (error) {
      setIsDeleting(false);
      openAlert(
        'Error',
        `Error deleting wave ${wave.waveID}: ${error.message}`,
        'closeAlertDeleteWaveError',
      );
    }
  }

  const handleOrderByChange = (event) => {
    setSortDirection(event.target.value);
  };

  const onClickDeleteWave = (waveId) => {
    const wave = waves.data.find((w) => w.waveID === waveId);
    if (wave) {
      setWaveToDelete(wave);
      setYesNoDialogText(
        `You are about to delete wave ${wave.waveID}. Are you sure?`,
      );
      setYesNoDialogOpen(true);
    } else {
      openAlert(
        "Wave can't be found",
        "The requested wave couldn't be found",
        'closeAlertDeleteWaveNotFoundError',
      );
    }
  };

  const getLabel = (filterField) => {
    if (filterField === 'fileIdFilter') return 'File ID';
    if (filterField === 'releaseIdFilter') return 'Bundle ID';
    if (filterField === 'deployIdFilter') return 'Deploy ID';
    return '';
  };

  const classes = useStyles();
  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSelectChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleChangeSelectedFilter = (event) => {
    setSelectedFilterField(event.target.value);
    setfilterValue(null);
    setDebouncedDeployIdFilter(null);
    setDebouncedReleaseIdFilter(null);
    setDebouncedFileIdFilter(null);
  };

  const handleChangeFilterValue = (value) => {
    setfilterValue(value);
    if (selectedFilterField === 'fileIdFilter') {
      setDebouncedFileIdFilter(value);
    }
    if (selectedFilterField === 'releaseIdFilter') {
      setDebouncedReleaseIdFilter(value);
    }
    if (selectedFilterField === 'deployIdFilter') {
      setDebouncedDeployIdFilter(value);
    }
  };

  if (!project || waves?.length === 0) {
    return <MessageCard message="No waves retrieved" />;
  }

  if ((isLoadingWaves || isLoadingWave || !project?.code) && !waveFromParams && !waves) {
    return (
      <>
        <ProgressDialog open header={isLoadingWave ? 'Retrieving the wave, please wait' : 'Retrieving waves, please wait'} />
        <LinearProgress style={{ width: '100%' }} />
      </>
    );
  }

  if (isDeleting) {
    return (
      <>
        <ProgressDialog open header="Deleting waves, please wait" />
        <LinearProgress style={{ width: '100%' }} />
      </>
    );
  }

  if (errorFetchingWaves) {
    return (
      <SimpleAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        header="Error"
        body={errorFetchingWaves.message}
        id="closeAlertErrorFetchingWaves"
      />
    );
  }

  if (waveFromParams) {
    return (
      <Box>
        <WaveAccordion
          wave={waveFromParams}
          key={waveFromParams.waveID}
          id="txtWavedetail"
          expandedProp
        />
      </Box>
    );
  }

  return (
    <Box>
      <YesNoDialog
        open={yesNoDialogOpen}
        setOpen={setYesNoDialogOpen}
        header="Delete Wave"
        body={yesNoDialogText}
        action={() => deleteWaveFunc(waveToDelete)}
        actionName="Yes"
        id="confirmationDeleteWave"
      />
      <SimpleAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        header={alertHeader}
        body={alertText}
        id={alertId}
      />
      <PageBanner title="WAVES" />

      {
        waveFileTypes?.data?.length > 0 && !isLoadingWaveFileTypes && (

          <Box display="flex" alignItems="flex-end" justifyContent="flex-end" m={3} style={{ gap: '16px' }}>

            <Box display="flex" flexDirection="row">
              <FormControl variant="filled" style={{ minWidth: 200 }}>
                <InputLabel>Filter</InputLabel>
                <Select
                  value={selectedFilterField}
                  onChange={handleChangeSelectedFilter}
                  label="Filter"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  <MenuItem key="fileIdFilterkey" value="fileIdFilter">
                    <Typography variant="body1">File ID</Typography>
                  </MenuItem>

                  <MenuItem key="releaseIdFilterkey" value="releaseIdFilter">
                    <Typography variant="body1">Bundle ID</Typography>
                  </MenuItem>

                  <MenuItem key="deployIdFilterkey" value="deployIdFilter">
                    <Typography variant="body1">Deploy ID</Typography>
                  </MenuItem>

                </Select>
              </FormControl>
              <FormControl variant="filled" style={{ minWidth: 400, marginLeft: 16 }}>
                <TextField
                  id="filterValueId"
                  variant="filled"
                  value={filterValue}
                  label={getLabel(selectedFilterField)}
                  onChange={(event) => {
                    handleChangeFilterValue(event.target.value);
                  }}
                  orientation="landscape"
                  inputVariant="filled"
                  className={classes.picker}
                  color="secondary"
                />
              </FormControl>
            </Box>

            {/* Sort and filter by type */}
            <Box>
              {/* <FormControl variant="filled" style={{ minWidth: 400 }}>

                <InputLabel className={classes.paginationSizeLabel}>File Type Filter</InputLabel>
                <Select
                  value={selectedWaveFileTypes}
                  onChange={handleDeploymentFileTypeFilterChange}
                  displayEmpty
                  multiple
                  open={openWaveTypeFilter}
                  onOpen={handleOpenWaveTypeFilter}
                  onClose={handleCloseTypeFilter}
                  className={classes.selectPaginationSize}
                  renderValue={(selected) => selected.join(', ')}
                >

                  {
                    waveFileTypes?.data.map((deploymentFileType) => (

                      <MenuItem key={deploymentFileType} value={deploymentFileType}>
                        <Checkbox checked={selectedWaveFileTypes.indexOf(deploymentFileType) > -1} />
                        <ListItemText primary={deploymentFileType} />
                      </MenuItem>

                    ))
                  }

                </Select>
              </FormControl> */}

              {/* Sort by order */}
              <FormControl variant="filled" style={{ minWidth: 130 }}>

                <InputLabel className={classes.paginationSizeLabel}>Sort by Date</InputLabel>
                <Select
                  value={sortDirection}
                  onChange={handleOrderByChange}
                  displayEmpty
                  className={classes.selectPaginationSize}
                >
                  <MenuItem value="ASC" key="ASC">ASC</MenuItem>
                  <MenuItem value="DESC" key="DESC">DESC</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

        )
      }

      {
        waves.data?.map((wave, i) => (
          <WaveAccordion
            wave={wave}
            key={wave.waveID}
            id={`accordion-${i}`}
            deleteWave={onClickDeleteWave}
          />
        ))
      }

      <div className={classes.tableIcons}>
        {waves?.pagination && (
          <PaginationComponent
            pageSize={pageSize}
            handleSelectChange={handleSelectChange}
            pagination={waves.pagination}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </Box>
  );
}
