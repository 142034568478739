import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Tooltip, Typography, IconButton, makeStyles,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { ReactComponent as GlobalDeploymentIcon } from '../../assets/ic_global_deployment.svg';
import { ReactComponent as RegexIcon } from '../../assets/ic_regex.svg';
import { ReactComponent as MarketIcon } from '../../assets/ic_market.svg';
import {
  TOOLTIP_GLOBAL_DEPLOYMENT, TOOLTIP_REGEX_DEPLOYMENT,
  TOOLTIP_MARKET_DEPLOYMENT, TOOLTIP_DEVICE_DEPLOYMENT, TOOLTIP_DEVICE_DEPLOYMENT_CSV,
  CSV_FILETYPE,
} from '../../utils/constants';
import isStringifiedObject from '../../utils/isStringifiedObject';
import tryParseArray from '../../utils/tryParseArray';
import { downloadFile } from '../../utils/exportUtils';

const useStyles = makeStyles((theme) => ({
  headingTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  downloadCloud: {
    padding: 0,
  },
  globalDeployIcon: {
    marginTop: 4,
  },
  icon: {
    color: theme.palette.primary.text,
  },
}));

function printNumberOfTargetMarkets(target) {
  try {
    const targetParsed = tryParseArray(target);
    return targetParsed.length;
  } catch (e) {
    return 1;
  }
}

function printNumberOfTargets(target) {
  try {
    if (target) {
      if (isStringifiedObject(target)) {
        const parsedTarget = JSON.parse(target);
        if (Array.isArray(parsedTarget)) {
          return parsedTarget.length;
        }
      } else {
        return 1;
      }
    }
    return undefined;
  } catch (e) {
    return '*';
  }
}

function convertToCSV(rows) {
  let header = '';
  // eslint-disable-next-line no-return-assign
  Object.keys(rows[0]).map((pr) => (header += `${pr};`));

  let str = '';
  rows.forEach((row) => {
    str += `${row}\r\n`;
  });
  return `${str}`;
}

function exportTargetToCSV(deployment, isBundle) {
  try {
    let { target } = deployment;

    if (!target) {
      window.alert('Couldn\'t export serials', 'No serials to export');
      return;
    }

    if (typeof target === 'string') {
      target = [target];
    }

    if (Array.isArray(target)) {
      // Add header
      target.unshift('serials');

      const csv = convertToCSV(target);

      const exportedFilename = isBundle ? `Bundle${deployment.deploymentID}_deploymentSerials` : `Deployment${deployment.deploymentID}_deploymentSerials`;

      downloadFile(csv, exportedFilename, CSV_FILETYPE);
    } else {
      window.alert('Couldn\'t export serials', 'Could not parse serials into .csv');
    }
  } catch (e) {
    window.alert('Couldn\'t export serials', 'There was an error exporting the serials into .csv');
  }
}

const DeploymentType = ({
  id,
  deployment,
}) => {
  const classes = useStyles();

  return (
    <Grid container display="flex" direction="row" alignItems="center" spacing={2} justifyContent="center">
      {!deployment.targetRegex && !deployment.targetMarket && deployment.target !== '*' && (
        <Grid item>
          <Typography className={classes.headingTitle} id={`${id}NMachines`}>
            {printNumberOfTargets(deployment.target)}
          </Typography>
        </Grid>
      )}
      {!deployment.targetRegex && !deployment.targetMarket && deployment.target !== '*' && (
        <Grid item>
          <Tooltip title={`${TOOLTIP_DEVICE_DEPLOYMENT}. ${TOOLTIP_DEVICE_DEPLOYMENT_CSV}`} aria-label="download">
            <IconButton
              aria-label="download-button"
              id={`${id}btnDownloadNMachines`}
              className={classes.downloadCloud}
              onClick={(event) => {
                event.stopPropagation();
                exportTargetToCSV(deployment, id.includes('bundle'));
              }}
            >
              <CloudDownloadIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {((!deployment.targetRegex) && (deployment.targetMarket === '*' || deployment.target === '*')) && (
        <Grid item className={classes.globalDeployIcon}>
          <Tooltip title={TOOLTIP_GLOBAL_DEPLOYMENT} aria-label="deployGlobal">
            <Typography className={classes.headingTitle}>
              <GlobalDeploymentIcon className={classes.icon} />
            </Typography>
          </Tooltip>
        </Grid>
      )}
      {deployment.targetRegex && (
        <Tooltip title={TOOLTIP_REGEX_DEPLOYMENT} aria-label="deployRegex" className={classes.globalDeployIcon}>
          <Typography className={classes.headingTitle}>
            <RegexIcon className={classes.icon} />
          </Typography>
        </Tooltip>
      )}
      {!deployment.target && !deployment.targetRegex && deployment.targetMarket && deployment.targetMarket !== '*' && (
        <>
          <Typography className={classes.headingTitle}>
            <p className={classes.headingTitleElements}>{printNumberOfTargetMarkets(deployment.targetMarket)}</p>
          </Typography>
          <Tooltip title={TOOLTIP_MARKET_DEPLOYMENT} aria-label="deployMarket">
            <IconButton disabled>
              <MarketIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Grid>
  );
};

DeploymentType.propTypes = {
  id: PropTypes.string.isRequired,
  deployment: PropTypes.shape({
    target: PropTypes.string,
    targetRegex: PropTypes.bool,
    targetMarket: PropTypes.string,
  }).isRequired,
};

export default DeploymentType;
