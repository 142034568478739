/* eslint-disable import/no-named-default */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import LoadingAnimation from '../LoadingAnimation';
import { default as logo } from '../../assets/portal_logo.svg';

const useStyles = makeStyles((theme) => ({
  alertHeader: {
    padding: 30,
  },
  alertProgress: {
    textAlign: 'center',
    marginBottom: 30,
    alignContent: 'center',
    alignItems: 'center',
  },
  alertText: {
    font: theme.typography.h3.font,
    fontSize: 16,
    color: theme.palette.common.black,
    paddingBottom: 8,
  },

}));

export default function ProgressDialog({
  open, setOpen, header,
}) {
  const classes = useStyles();

  const dialogRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const titleName = 'Consumer IoT Portal';

  return (
    <Dialog
      ref={dialogRef}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.customAlert}
    >
      <DialogContent>
        <DialogContentText className={classes.alertProgress}>
          <Box m={1}>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Typography className={classes.headingTitle} variant="h2">
              {titleName}
            </Typography>
          </Box>
          <LoadingAnimation title={header || 'Alert'} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
ProgressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  header: PropTypes.string.isRequired,
};

ProgressDialog.defaultProps = {
  setOpen: () => { },
};
