/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, Table,
  TableBody, TableContainer, TableFooter, TablePagination, TableRow,
  LinearProgress, Paper, TableHead, Card, CardContent, Typography,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import {
  React,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import GraphqlService from '../../../service/graphqlService';
import BrandConsumptionsListItem from '../../../components/MachineTelemetry/BrandConsumptionsListItem';
import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import SecondaryButton from '../../../components/utils/SecondaryButton';
import { useSnackbar } from '../../../providers/SnackbarContext';
import { useProject } from '../../../config/ProjectContext';
import { StyledTableCell, StyledTableRow, StyledTableHeaderCell } from '../../../components/utils/StyledTable';

const useStyles = makeStyles((theme) => ({

  bottomRightExportButton: {
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 16,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 0,
    width: 138,
  },
  historyTable: {
    borderRadius: 0,
    '& tr': {
      height: 48,
      '& td': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 12,
        height: 48,
      },
    },
  },
  separateVoidGrid: {
    flexWrap: 'noWrap',
    borderRadius: '12px !important',
  },
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.typography,
  },
  exportCSVText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
  },
  contentGrid: {
    paddingBottom: 16,
  },
  cardNoMachineTelemetryText: {
    color: theme.palette.primary.text,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    textAlign: 'center',
  },
  detailsTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: '5px',
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  machineInfoCardPadding16: {
    padding: '16px !important',
  },
  telemetryTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  bottomLeftLegend: {
    paddingTop: 4,
  },
  cardNoMachineTelemetry: {
    borderRadius: 12,
  },
  footerGrid: {
    width: 9,
  },
  toolbar: {
    width: 350,
  },
}));

const processHeaderTitle = (title) => {
  // separate words from title (we assume that words are separated by camelCase)
  const words = title.match(/[A-Za-z][a-z]*/g);
  // decapitalize first letter of each word
  const decapitalizedWords = words.map((word) => word.charAt(0).toLowerCase() + word.substring(1));
  // return the array of capitalized words joined by space
  return decapitalizedWords.join('_');
};

const getHeaderExport = (dataList) => {
  const headerExport = [];
  if (dataList && dataList.length > 0) {
    Object.entries(dataList[0]).forEach(([k, v]) => {
      if (k === 'dictProduct') {
        headerExport.push('dict_product.code');
        headerExport.push('dict_product.market');
        headerExport.push('dict_product.name');
        headerExport.push('dict_product.inserted_on');
        headerExport.push('dict_product.updated_on');
        headerExport.push('dict_product.is_deleted');
      } else if (k === 'dictRecipe') {
        headerExport.push('dict_recipe.code');
        headerExport.push('dict_recipe.market');
        headerExport.push('dict_recipe.name');
        headerExport.push('dict_recipe.extra');
        // headerExport.push('dict_recipe.inserted_on');
        // headerExport.push('dict_recipe.updated_on');
        // headerExport.push('dict_recipe.is_deleted');
      } else {
        headerExport.push(processHeaderTitle(k));
      }
    });
  }
  return headerExport;
};

export default function BrandConsumptionsTable({
  search, dataList, totalCount, filters, loading, dateIni, dateEnd,
  page, setPage, rowsPerPage,
}) {
  const { project } = useProject();

  const headerExport = getHeaderExport(dataList);
  const openSnackBar = useSnackbar();

  const createNotificationMutation = useMutation((variables) => GraphqlService.createNotification(variables.email, variables.notificationTitle, variables.notificationDescription));

  async function onClickExport() {
    try {
      const userToken = await Auth.currentAuthenticatedUser();
      const email = userToken?.signInUserSession?.idToken?.payload?.identities?.shift().userId || userToken?.attributes?.email;
      await createNotificationMutation.mutateAsync({ email, notificationTitle: 'CSV file is being generated', notificationDescription: 'Your CSV file is being generated. You will receive a notification with the download link shortly.' });
      await GraphqlService.getBrandConsumptionsCSV(
        project.code, filters, dateIni, dateEnd,
      );
      openSnackBar('CSV file is being generated', 'info');
    } catch (error) {
      openSnackBar(error.message, 'error');
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    search(newPage);
  };

  const classes = useStyles();

  return (
    <Box>
      <Grid
        container
        spacing={0}
        direction="row"
        className={classes.content}
        id="brand-consumptions-table"
      >
        {(dataList !== null) && (dataList.length > 0)
          && (
            <Grid
              container
              spacing={0}
              direction="row"
              className={classes.contentGrid}
            >
              <Grid item xs={6} className={classes.bottomLeftLegend}>
                <Typography className={classes.bottomLeftLegend}>Data will be retrieved in real time</Typography>
              </Grid>

              <Grid item xs={6} className={classes.bottomRightExportButton}>

                <span>
                  <SecondaryButton
                    id="btnConsumptionsExport"
                    onClick={() => onClickExport()}
                  >
                    Export .CSV
                  </SecondaryButton>
                </span>

              </Grid>

              <Grid
                item
                xs={12}
                className={classes.separate}
              >
                <TableContainer component={Paper} className={classes.telemetryTable}>
                  <Table className={classes.historyTable} aria-label="custom pagination table">
                    <TableHead>
                      <TableRow>
                        {headerExport.map((head) => <StyledTableCell>{head}</StyledTableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataList.map((item, i) => (
                        <BrandConsumptionsListItem
                          consumption={item}
                          id={`cellDeviceConsumption${i}`}
                        />
                      ))}
                    </TableBody>
                    <Grid className={classes.footerGrid}>
                      <TableFooter>
                        <TableRow key="paginationFooter">
                          <TablePagination
                            rowsPerPageOptions={[20]}
                            colSpan={10}
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            classes={{ toolbar: classes.toolbar }}
                            SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true,
                            }}
                            onChangePage={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Grid>
                  </Table>
                  {loading && <LinearProgress />}
                </TableContainer>

              </Grid>
            </Grid>

          )}
        {(dataList !== null) && (dataList.length === 0)
          && !loading
          && (
            <Grid
              item
              xs={12}
              className={classes.separateVoidGrid}
            >
              <Card className={classes.cardNoMachineTelemetry}>
                <CardContent className={classes.machineInfoCardPadding16}>
                  <Typography className={classes.cardNoMachineTelemetryText}>
                    No device consumptions available
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
      </Grid>
    </Box>
  );
}
BrandConsumptionsTable.propTypes = {
  search: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  filters: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  dateIni: PropTypes.object.isRequired,
  dateEnd: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
