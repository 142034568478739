/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  makeStyles,
  Typography,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  InputLabel,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useQuery, useMutation } from '@tanstack/react-query';
import GraphqlService from '../../service/graphqlService';
import { useProject } from '../../config/ProjectContext';
import ProgressDialog from '../../components/utils/ProgressDialog';
import DeploymentType from '../../components/DeploymentList/DeploymentType';
import { useSnackbar } from '../../providers/SnackbarContext';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  detailIcon: {
    padding: 0,
  },
  button: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    backgroundColor: '#3F51B5',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
  },
  description: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

function BundleDetail({ releaseID, onDescriptionUpdate }) {
  const classes = useStyles();
  const { project } = useProject();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [originalTitle, setOriginalTitle] = useState('');
  const [originalDescription, setOriginalDescription] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const openSnackBar = useSnackbar();

  const { data: bundleDetail, isLoading } = useQuery(
    ['bundleDetail', releaseID],
    () => GraphqlService.getBundle({ projectId: project?.code, releaseID }),
    {
      enabled: !!project?.code,
      onSuccess: (data) => {
        setTitle(data?.title || '');
        setDescription(data?.releaseDescription?.description || '');
        setOriginalTitle(data?.title || '');
        setOriginalDescription(data?.releaseDescription?.description || '');
      },
    },
  );

  const { data: bundleWaves, isLoading: bundleWavesLoading } = useQuery(
    ['bundleWaves', releaseID],
    () => GraphqlService.listPaginatedWaves({ projectId: project?.code, releaseID }),
    {
      enabled: !!project?.code,
    },
  );

  const { data: bundleDeployments, isLoading: bundleDeploymentsLoading } = useQuery(
    ['bundleDeployments', releaseID],
    () => GraphqlService.getBundleDeployments({ projectId: project?.code, releaseID }),
    {
      enabled: !!project?.code,
    },
  );

  const updateBundleMutation = useMutation(
    (updatedData) => GraphqlService.updateBundle(project?.code, releaseID, updatedData.title, updatedData.description),
    {
      onSuccess: () => {
        setOriginalTitle(title);
        setOriginalDescription(description);
        setIsChanged(false);
      },
    },
  );

  // Update `isChanged` based on changes in title or description
  useEffect(() => {
    setIsChanged(title !== originalTitle || description !== originalDescription);
  }, [title, description, originalTitle, originalDescription]);

  const handleSave = () => {
    updateBundleMutation.mutate(
      {
        title,
        description,
      },
      {
        onSuccess: () => {
          openSnackBar('Description updated successfully!', 'success');
          setOriginalTitle(title);
          setOriginalDescription(description);
          setIsChanged(false);
          if (onDescriptionUpdate) {
            onDescriptionUpdate();
          }
        },
        onError: (error) => {
          openSnackBar(`Failed to update description: ${error.message || 'Unknown error'}`, 'error');
        },
      },
    );
  };

  const openAsset = (fileId) => {
    const url = `${window.location.origin}/#/assetDetail/${fileId}`;
    window.open(url, '_blank');
  };

  const openDeployment = (deploymentID) => {
    const url = `${window.location.origin}/#/deploymentDetail/${deploymentID}`;
    window.open(url, '_blank');
  };

  const openWave = (waveID) => {
    const url = `${window.location.origin}/#/waveDetail/${waveID}`;
    window.open(url, '_blank');
  };

  const getVersionFromString = (stringValue) => {
    const versionMatch = stringValue?.match(/Version=([^,}]*)/);
    if (versionMatch && versionMatch[1]) {
      return versionMatch[1].trim();
    }
    return undefined;
  };

  if (isLoading) {
    return <ProgressDialog open header="Retrieving detail, please wait" />;
  }

  return (
    <Grid container spacing={2}>
      {/* Editable Title */}
      <Grid item xs={12} md={6}>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Date"
          value={bundleDetail.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}
          fullWidth
          disabled
        />
      </Grid>

      {/* Editable Description */}
      <Grid item xs={12}>
        <InputLabel htmlFor="description">Description</InputLabel>
        <TextField
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          className={classes.description}
        />
      </Grid>

      {/* Save Button */}
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.button}
          disabled={!isChanged || updateBundleMutation.isLoading}
          onClick={handleSave}
        >
          {updateBundleMutation.isLoading ? 'Saving...' : 'Save'}
        </Button>
      </Grid>

      {/* Render Assets */}
      {bundleDetail?.fileIDs?.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Assets</Typography>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="assets table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {bundleDetail.fileIDs.map((asset) => (
                  <TableRow key={asset.fileID}>
                    <TableCell>{asset.type}</TableCell>
                    <TableCell>{getVersionFromString(asset.assetDescription)}</TableCell>
                    <TableCell>{asset.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell>
                      <IconButton className={classes.detailIcon} onClick={() => openAsset(asset.fileID)}>
                        <ExitToAppIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      {/* Render Deployments */}
      {bundleDeployments?.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Deployments</Typography>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="deployments table">
              <TableHead>
                <TableRow>
                  <TableCell>Deploy ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {bundleDeployments.map((deployment) => (
                  <TableRow key={deployment.deployID}>
                    <TableCell>{deployment.deployID}</TableCell>
                    <TableCell>{deployment.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell>
                      <DeploymentType
                        id={`deployment-${deployment.deployID}`}
                        deployment={deployment}
                      />
                    </TableCell>
                    <TableCell>{deployment.deployDescription?.userDescription}</TableCell>
                    <TableCell>
                      <IconButton className={classes.detailIcon} onClick={() => openDeployment(deployment.deployID)}>
                        <ExitToAppIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      {/* Render the waves of the selected bundle */}
      {bundleWaves?.data?.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Waves</Typography>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="waves table">
              <TableHead>
                <TableRow>
                  <TableCell>Wave ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {bundleWaves.data.map((wave) => (
                  <TableRow key={wave.waveID}>
                    <TableCell>{wave.waveID}</TableCell>
                    <TableCell>{wave.dateTime?.toString('dd/MM/yyyy HH:mm:ss')}</TableCell>
                    <TableCell>
                      <DeploymentType
                        id={`wave-${wave.waveID}`}
                        deployment={wave}
                      />
                    </TableCell>
                    <TableCell>{wave.waveDescription?.userDescription}</TableCell>
                    <TableCell>
                      <IconButton className={classes.detailIcon} onClick={() => openWave(wave.waveID)}>
                        <ExitToAppIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
}

BundleDetail.propTypes = {
  releaseID: PropTypes.string.isRequired,
  onDescriptionUpdate: PropTypes.func,
};

export default BundleDetail;
